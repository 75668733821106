/*
	import { daysOfWeek } from '@/utilities/Date/constantsDaysOfWeek'
*/

export const daysOfWeek = Object.freeze([
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
])
