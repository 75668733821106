import _get from 'lodash/get'
import moment from 'moment'

import { notifyMessage, notifyProblem } from '@/services/notify'

import { eraseEvent } from '@/services/CalendarEvent/CommunityCalendarEvent/Delete'
import { updateEvent } from '@/services/CalendarEvent/CommunityCalendarEvent/Put'

import { calendarEventStore } from '@/services/CalendarEvent/store'

export const methods = {
  open({ event }) {
    console.debug('event=' + JSON.stringify(event))

    const { startDate, endDate } = event

    const romanceStart = moment(startDate.split('Z')[0]).format('LL hh:mm A')
    const romanceEnd = moment(endDate.split('Z')[0]).format('LL hh:mm A')

    this.eventStart = startDate
    this.eventEnd = endDate

    /*
			For Example:
				10:00am January 1st, 2022 to 4:00pm January 5th, 2022
		*/

    this.loading = false
    this.isOpen = true

    this.title = event.name
    this.description = event.description
    this.url = event.url

    this.dates = romanceStart + ' to ' + romanceEnd

    this.communityCalendarEventID = event.communityCalendarEventID
  },

  showUpdatePanel() {
    this.page = 1
  },

  showDeletionConfirmation() {
    this.page = 2
  },

  back() {
    this.page = 0
  },

  async deleteEvent() {
    this.loading = false

    const communityCalendarEventID = this.communityCalendarEventID

    const { successful, message } = await eraseEvent({
      communityCalendarEventID
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    notifyMessage('The event has been deleted.')

    await calendarEventStore.commit('updateMain')

    this.loading = true
    this.isOpen = false
  },

  async updateEvent() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const communityCalendarEventID = this.communityCalendarEventID

    const dateTimePicker1 = this.$refs.dateTimePicker1.getValue()
    const dateTimePicker2 = this.$refs.dateTimePicker2.getValue()

    const { successful, message } = await updateEvent({
      json: {
        hoaID,
        communityCalendarEventID,

        title: this.title,
        description: this.description,
        url: this.url,
        startTime: dateTimePicker1.value,
        endTime: dateTimePicker2.value
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    notifyMessage('The event has been updated.')

    await calendarEventStore.commit('updateMain')

    this.loading = true
    this.isOpen = false
  }
}
