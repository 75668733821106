<template>
  <Modal event-adder :toggle.sync="isOpen" :loading="loading">
    <template v-slot:header>
      <div>Event Adder</div>
    </template>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'space-between' }">
        <div></div>
        <b-button @click="add" type="is-primary">Add</b-button>
      </div>
    </template>

    <b-field label="Title">
      <b-input v-model="fields.title"></b-input>
    </b-field>

    <b-field label="Description">
      <b-input v-model="fields.description" type="textarea"></b-input>
    </b-field>

    <b-field label="URL">
      <b-input v-model="fields.url"></b-input>
    </b-field>

    <b-field label="From" />
    <div :style="{ display: 'flex', alignItems: 'center' }">
      <DatePicker
        ref="startDate"
        ariaLabel="filter from date"
        ariaPrefix="from"
        :style="{ paddingRight: '10px', marginBottom: 0 }"
      />
      <TimePicker ref="startTime" />
    </div>

    <br />

    <b-field label="To" />
    <div :style="{ display: 'flex', alignItems: 'center' }">
      <DatePicker
        ref="endDate"
        ariaLabel="filter from date"
        ariaPrefix="from"
        :style="{ paddingRight: '10px', marginBottom: 0 }"
      />
      <TimePicker ref="endTime" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import TimePicker from '@/components/inputs/TimePickerFifteen'
import DatePicker from '@/components/inputs/DatePicker'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    TimePicker,
    DatePicker
  },
  data,
  methods
}
</script>
