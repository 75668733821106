/*
	import { eraseRecurringEvent } from '@/services/CalendarEvent/CommunityCalendarRecurringEvent/Delete';

  const { result, successful, message } = await eraseRecurringEvent ({
    communityCalendarRecurringEventID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function eraseRecurringEvent({ communityCalendarRecurringEventID }) {
  let message = 'The community calendar event could not be erased.'

  try {
    const result = await kms.delete(
      `/CalendarEvent/CommunityCalendarRecurringEvent/${communityCalendarRecurringEventID}?forceDelete=true`
    )

    return {
      successful: true,
      message,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: {}
  }
}
