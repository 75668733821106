import moment from 'moment'
import { months } from '@/utilities/Date/constantsMonths'

export function data() {
  return {
    json: {},

    year: moment().year(),
    //
    checked: {
      month: false,
      dayOfMonth: false,
      dayOfWeek: false,
      occasionOfDayOfWeek: false,
      time: false
    },

    color: {
      0: 'purple'
    },

    month: {
      options: [
        [1, 'is (either)'],
        [2, '(and day) is on or between']
      ],
      optionSelected: 1
    },
    months,
    filteredMonths: months,
    monthsSelected: [],

    //
    //
    monthInclusiveBetween: [],

    //
    //	days of month
    //

    dayOfMonth: {
      options: [
        [1, 'is (either) the'],
        [2, 'is on or between the']
      ],
      optionSelected: 1
    },
    daysOfMonthSelected: [],
    filteredDaysOfMonth: this.getAllPossibleDaysOfMonth(),
    dayOfMonth1Value: '2nd',
    dayOfMonth2Value: '3rd',

    //
    //
    //

    weeksOfYearSelected: [],
    filteredWeeksOfYear: this.getWeeksOfYear(),

    //
    //
    //

    dayOfWeek: {
      options: [
        [1, 'is on the'],
        [2, 'is']
      ],
      optionSelected: 1
    },
    daysOfWeekSelected: [],

    //
    //
    //

    time: '24:00:00', // start time
    minutes: 0, // duration

    //

    radio: {
      monthType: '1',
      dayOfMonthType: '1',
      dayOfWeekType: 'is'
    }
  }
}
