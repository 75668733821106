import { allDatesInMonth } from '@/utilities/Date/allDatesInMonth'
import { addSuffix } from '@/utilities/Date/addSuffix'

/*
	recurrenceDefinition: {
		month: [],
		monthInclusiveBetween: [],
		
		dayOfMonth: [],
		
		dayOfMonthInclusiveBetween: [],
		dayOfWeek: [ 'Tuesday' ],

		startTime: null,
		durationMinutes: null,
		
		startDate: '2022-01-01',
		endDate: null
	}
*/

export const methods = {
  getValues() {
    const checked = this.checked

    var results = {}
    if (checked.month) {
      if (this.month.optionSelected === 1) {
        const monthsSelected = this.monthsSelected
        results.month = monthsSelected
      } else if (this.month.optionSelected === 2) {
        const monthAndDay1 = this.$refs.monthAndDay1.getValues()
        const monthAndDay2 = this.$refs.monthAndDay2.getValues()

        results.monthInclusiveBetween = [
          monthAndDay1.month + '-' + monthAndDay1.dayOfMonth,
          monthAndDay2.month + '-' + monthAndDay2.dayOfMonth
        ]
      }
    }
    if (checked.dayOfMonth) {
      if (this.dayOfMonth.optionSelected === 1) {
        const daysOfMonthSelected = this.daysOfMonthSelected

        const dayOfMonth = []
        for (let a = 0; a < daysOfMonthSelected.length; a++) {
          if (daysOfMonthSelected[a].toLowerCase() === 'last') {
            dayOfMonth.push(daysOfMonthSelected[a])
            continue
          }

          dayOfMonth.push(daysOfMonthSelected[a].slice(0, -2))
        }

        results.dayOfMonth = dayOfMonth
      } else if (this.dayOfMonth.optionSelected === 2) {
        const dayOfMonth1 = this.$refs.dayOfMonth1.getValueWithoutSuffix()
        const dayOfMonth2 = this.$refs.dayOfMonth2.getValueWithoutSuffix()

        results.dayOfMonthInclusiveBetween = [dayOfMonth1, dayOfMonth2]
      }
    }
    if (checked.dayOfWeek) {
      const daysOfWeekSelected = this.daysOfWeekSelected

      const _dayOfWeek = []
      for (let a = 0; a < daysOfWeekSelected.length; a++) {
        const { dayOfWeek, occasion } = daysOfWeekSelected[a]

        let strand = dayOfWeek

        if (occasion !== 'Any') {
          strand += '-'
          strand += occasion.slice(0, -2)
        }

        _dayOfWeek.push(strand)
      }

      results.dayOfWeek = _dayOfWeek
    }
    if (checked.time) {
      const time = this.$refs.time.getValue()[24]
      const minutes = this.minutes

      results.startTime = time
      results.durationMinutes = parseInt(minutes)
    }

    this.recurrenceDefinition = results
    this.json = {
      recurrenceDefinition: results
    }

    return results
  },

  // for testing purposes
  /*	
		setValue ({ monthsSelected: [] })
	*/
  setValue(params) {
    for (const key in params) {
      this[key] = params[key]
    }
  },

  setValues({ recurringEvent }) {
    const { recurrenceDefinition } = recurringEvent
    const {
      // first box
      month,
      monthInclusiveBetween,

      // second box
      dayOfMonth,
      dayOfMonthInclusiveBetween,

      // third box
      dayOfWeek,

      // fourth box
      startTime,
      durationMinutes
    } = recurrenceDefinition

    this.checked.month = false
    this.checked.dayOfMonth = false
    this.checked.dayOfWeek = false
    this.checked.time = false

    // first box
    if (Array.isArray(month) && month.length >= 1) {
      this.checked.month = true
      this.month.optionSelected = 1
      this.monthsSelected = month
    }
    if (Array.isArray(monthInclusiveBetween) && monthInclusiveBetween.length >= 1) {
      this.checked.month = true
      this.month.optionSelected = 2
      this.monthInclusiveBetween = monthInclusiveBetween
    }

    // second box
    if (Array.isArray(dayOfMonth) && dayOfMonth.length >= 1) {
      this.checked.dayOfMonth = true
      this.dayOfMonth.optionSelected = 1

      this.daysOfMonthSelected = dayOfMonth.map(day => {
        return addSuffix(day)
      })
    }
    if (Array.isArray(dayOfMonthInclusiveBetween) && dayOfMonthInclusiveBetween.length >= 1) {
      this.checked.dayOfMonth = true
      this.dayOfMonth.optionSelected = 2

      this.dayOfMonth1Value = addSuffix(dayOfMonthInclusiveBetween[0])
      this.dayOfMonth2Value = addSuffix(dayOfMonthInclusiveBetween[1])
    }

    // third box
    if (Array.isArray(dayOfWeek) && dayOfWeek.length >= 1) {
      this.checked.dayOfWeek = true
      this.daysOfWeekSelected = dayOfWeek.map(entry => {
        if (entry.indexOf('-') === -1) {
          return {
            dayOfWeek: entry,
            occasion: 'Any'
          }
        }

        const split = entry.split('-')

        let occasion = split[1]
        if (occasion == 1) {
          occasion += 'st'
        } else if (occasion == 2) {
          occasion += 'nd'
        } else if (occasion == 3) {
          occasion += 'rd'
        } else if (occasion == 4) {
          occasion += 'th'
        } else if (occasion == 5) {
          occasion += 'th'
        }

        return {
          dayOfWeek: split[0],
          occasion
        }
      })
    }

    // fourth box
    if (typeof startTime === 'string' && startTime.length === 8) {
      this.checked.time = true
      //
      this.time = startTime
      this.minutes = durationMinutes
      //
    }
  },

  getFilteredMonths(text) {
    this.filteredMonths = this.months.filter(option => {
      if (option.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
        return true
      }

      return false
    })
  },
  getFilteredDaysOfTheMonth(text) {
    this.filteredDaysOfMonth = this.getAllPossibleDaysOfMonth().filter(option => {
      if (option.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
        return true
      }

      return false
    })

    return []
  },
  getFilteredWeeksOfYear(text) {
    this.filteredWeeksOfYear = this.getWeeksOfYear().filter(option => {
      if (option.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
        return true
      }

      return false
    })

    return []
  },
  getAllPossibleDaysOfMonth() {
    const { dates } = allDatesInMonth({
      year: 2000,
      month: 1,
      suffix: true
    })

    return ['last', ...dates]
  },

  getWeeksOfYear() {
    const possibilities = ['odd', 'even']
    for (let a = 1; a <= 52; a++) {
      possibilities.push(a.toString())
    }

    return possibilities
  },
  addDayOfWeek() {
    const occasion = this.$refs.occasionOfDayOfWeek.getValue()
    const dayOfWeek = this.$refs.dayOfWeek.getValue()

    let added = false
    const list = this.daysOfWeekSelected
    for (let a = 0; a < list.length; a++) {
      const place = list[a]

      if (place.occasion === occasion && place.dayOfWeek === dayOfWeek) {
        added = true
        break
      }
    }

    if (!added) {
      this.daysOfWeekSelected.push({
        occasion,
        dayOfWeek
      })
    }
  },
  eraseDayOfWeek({ dayOfWeek }) {
    const daysOfWeekSelected = this.daysOfWeekSelected

    for (let a = 0; a < daysOfWeekSelected.length; a++) {
      const { occasion, dayOfWeek: _dayOfWeek } = daysOfWeekSelected[a]

      if (occasion === dayOfWeek.occasion && _dayOfWeek === dayOfWeek.dayOfWeek) {
        this.daysOfWeekSelected.splice(a, 1)
        break
      }
    }
  },

  //
  //
  //

  onMonthChange() {},
  onOccasionOfDayOfWeekChange() {},
  onDayOfWeekChange() {},

  sendChange() {
    const values = this.getValues()

    this.onChange(values)
  }
}

///
