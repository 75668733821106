<template>
  <Modal
    event-adder
    :toggle.sync="isOpen"
    :loading="loading"
    :styles="{ modalContent: { minHeight: '400px' } }"
    recurring-event-adder
  >
    <template v-slot:header>
      <div v-if="mode === 'add'">Add Recurring Event</div>
      <div v-if="mode === 'view'">
        <div :style="{ margin: '12px 0' }">
          <h4 :style="{ margin: 0 }">{{ title }}</h4>
          <div>{{ dates }}</div>
        </div>
      </div>
      <div v-if="mode === 'update'">Update (or Delete) Recurring Event</div>
    </template>

    <div
      v-if="mode === 'delete'"
      :style="{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '100px 0'
      }"
    >
      <div
        :style="{
          maxWidth: '400px'
        }"
      >
        To confirm deletion of event {{ this.json.title }}, please press "Delete".
      </div>

      <div :style="{ paddingTop: '40px' }">
        <b-button @click="eraseEvent" type="is-danger">Delete</b-button>
      </div>
    </div>

    <div v-if="mode === 'view'">
      <div>
        <div>{{ `Description: ${description}` }}</div>
        <div v-if="url">
          {{ `URL:` }} <a :href="`http://${url}`" target="_blank">{{ url }}</a>
        </div>
      </div>
    </div>

    <b-steps
      v-if="mode !== 'delete' && mode !== 'view'"
      size="is-small"
      v-model="step"
      :has-navigation="false"
    >
      <b-step-item label="Details" details-tab>
        <b-field label="Title">
          <b-input v-model="title" recurring-event-title></b-input>
        </b-field>

        <b-field label="Description">
          <b-input v-model="description" type="textarea" recurring-event-description></b-input>
        </b-field>

        <b-field label="URL">
          <b-input v-model="url" recurring-event-url></b-input>
        </b-field>
      </b-step-item>

      <b-step-item label="Pattern">
        <h4>The event occurs while</h4>
        <VariableRanges ref="variableRanges" :presets="variableRangesPresets" />
      </b-step-item>

      <b-step-item label="Filter">
        <h4>The event occurs</h4>

        <div :style="{ display: 'flex' }">
          <b-field :style="{ margin: 0, width: '300px' }">
            <h4>after (required)</h4>
          </b-field>
          <DatePicker ref="datePickerAfter" date-picker-after />
        </div>

        <div :style="{ display: 'flex', marginTop: '12px' }">
          <b-field :style="{ margin: 0, width: '300px' }">
            <b-checkbox v-model="beforeTimeSelected">
              <h4>before (optional)</h4>
            </b-checkbox>
          </b-field>

          <DatePicker ref="datePickerBefore" date-picker-before />
        </div>
      </b-step-item>

      <b-step-item label="Confirm">
        <Confirmation :confirmationData="confirmationData" />
      </b-step-item>
    </b-steps>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'space-between' }">
        <div v-if="mode !== 'view' && mode !== 'delete'" :style="{ float: 'right' }">
          <b-button
            vio-reporter-prev-button
            icon-left="chevron-left"
            size="is-medium"
            :style="{ marginRight: '8px' }"
            @click="prevStep"
            recurring-event-adder-prev-step
          ></b-button>

          <b-button
            vio-reporter-next-button
            icon-left="chevron-right"
            size="is-medium"
            @click="nextStep"
            recurring-event-adder-next-step
          ></b-button>
        </div>

        <div>
          <span class="pl-2"
            ><b-button v-if="mode === 'update'" @click="openConfirmErase" type="is-danger"
              >Delete</b-button
            ></span
          >

          <span :style="{ padding: '0 30px' }"></span>

          <span class="pl-2"
            ><b-button
              v-if="mode === 'update'"
              @click="update"
              type="is-primary"
              :disabled="updatePrevented"
              >Update</b-button
            ></span
          >

          <span class="pl-2"
            ><b-button
              class="float-left"
              v-if="mode === 'view'"
              @click="setUpdate()"
              type="is-primary"
              >Edit</b-button
            ></span
          >
        </div>

        <b-button
          v-if="mode === 'add'"
          @click="add"
          type="is-primary"
          :disabled="addPrevented"
          recurring-event-adder-add
          >Add</b-button
        >
      </div>
    </template>
  </Modal>
</template>

<script>
import Confirmation from './components/Confirmation'
import VariableRanges from './components/VariableRanges'
import Modal from '@/components/Modal'
import DatePicker from '@/components/inputs/DatePicker'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    Confirmation,
    VariableRanges,
    Modal,
    DatePicker
  },
  data,
  methods
}
</script>
