/*
	import { eraseEvent } from '@/services/CalendarEvent/CommunityCalendarEvent/Delete';

  const { list, successful, message } = await eraseEvent ({
    communityCalendarEventID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function eraseEvent({ communityCalendarEventID }) {
  let message = 'The community calendar event could not be erased.'

  try {
    const result = await kms.delete(
      `/CalendarEvent/CommunityCalendarEvent/${communityCalendarEventID}?forceDelete=true`
    )

    return {
      successful: true,
      message,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: {}
  }
}
