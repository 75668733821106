<template>
  <div>
    <h4>The event occurs while these conditions are met</h4>
    <div>
      <div v-for="(condition, index) in conditions" :key="index">{{ condition }}</div>
    </div>
  </div>
</template>

<script>
import { watch } from './keys/watch'

export default {
  props: {
    confirmationData: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      conditions: []
    }
  },
  watch
}
</script>
