export const presets = Object.freeze({
  variableRangesPresets: {},

  json: {
    recurrenceDefinition: {}
  },
  isOpen: false,
  loading: false,
  durationTypeSelection: 1,
  durationType: Object.freeze([
    [1, 'On'],
    [2, 'From ~ To']
  ]),
  title: '',
  description: '',
  url: '',
  year: 0,
  from: '',
  step: 0,
  beforeTimeSelected: false,
  addPrevented: true,
  updatePrevented: true,
  confirmationData: {},
  mode: 'add',
  dates: ''
})
