<template>
  <b-select v-model="dayOfWeekSelection">
    <option v-for="option in daysOfWeek" :value="option" :key="option">
      {{ option }}
    </option>
  </b-select>
</template>

<script>
import { daysOfWeek } from '@/utilities/Date/constantsDaysOfWeek'

export default {
  props: {
    onChange: {
      default() {},
      type: Function
    }
  },
  data() {
    return {
      daysOfWeek,
      dayOfWeekSelection: 'Tuesday'
    }
  },
  methods: {
    getValue() {
      return this.dayOfWeekSelection
    }
  },
  watch: {
    dayOfWeekSelection() {
      this.onChange({
        dayOfWeek: this.dayOfWeekSelection
      })
    }
  }
}
</script>
