import _get from 'lodash/get'

import { recurringEventConditions } from '@/utilities/events/recurringEventConditions'

export const watch = {
  /*
		The event occurs while these conditions are met:

			{
				"communityCalendarRecurringEventID": 0,
				"hoaID": 15,
				"recurrenceJSON": "",

				"title": "Test Event 5",
				"description": "Test event description",
				"url": "",

				"createdDate": "2022-02-11T04:58:05.116Z",
				"modifiedDate": "2022-02-11T04:58:05.116Z",

				"recurrenceDefinition": {
	        month: [],
	        	// month: [ "January", "February" ]

	        monthInclusiveBetween: [],
	        	// monthInclusiveBetween: [ "January-01", "February-03" ]

					dayOfMonth: [],
						// dayOfMonth: [ "1", "4", "23" ]

					dayOfMonthInclusiveBetween: [],
						// 

					dayOfWeek: [ 'Tuesday' ],


	        startTime: null,
	        durationMinutes: null,


	        startDate: '2022-01-01',
	        endDate: null
	      }
			}

	*/
  confirmationData({ json }) {
    const recurrenceDefinition = _get(json, 'recurrenceDefinition', {})
    const { conditions } = recurringEventConditions({
      recurrenceDefinition
    })

    this.conditions = conditions
  }
}
