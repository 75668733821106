import _get from 'lodash/get'
import moment from 'moment'

import { addEvent } from '@/services/CalendarEvent/CommunityCalendarEvent/Post'
import { notifyProblem } from '@/services/notify'
import { calendarEventStore } from '@/services/CalendarEvent/store'

function getDate({ time, date }) {
  try {
    const result = moment(date).utcOffset(0, true)
    const sTime = moment(time, 'HH:mm:ss')

    result.set({
      hour: sTime.get('hour'),
      minute: sTime.get('minute'),
      second: sTime.get('second')
    })

    return result.toISOString()
  } catch (x) {
    console.error(x)
  }

  return ''
}

export const methods = {
  open() {
    this.isOpen = true
    this.loading = false
  },

  onUpdate() {},

  refreshFields() {
    console.debug('refreshFields...')
    this.fields.title = ''
    this.fields.description = ''
    this.fields.url = ''
  },

  async add() {
    this.loading = true

    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    const { title, description, url } = this.fields

    const { allValid, utcFormat } = this.$refs.startDate.getValue()
    if (!allValid) {
      notifyProblem(`A valid start date has not been selected.`)
      return
    }
    const startTime = getDate({
      time: this.$refs.startTime.getValue()[24],
      date: utcFormat
    })

    const { allValid: endAllValid, utcFormat: endUTCFormat } = this.$refs.endDate.getValue()
    if (!endAllValid) {
      notifyProblem(`A valid start date has not been selected.`)
      return
    }
    const endTime = getDate({
      time: this.$refs.endTime.getValue()[24],
      date: endUTCFormat
    })

    const { successful, message } = await addEvent({
      json: {
        hoaID,
        title,
        url,
        description,
        startTime,
        endTime
      }
    })
    if (!successful) {
      notifyProblem(message)

      this.loading = false
      this.isOpen = false

      return
    }

    this.refreshFields()

    await calendarEventStore.commit('updateMain')

    this.loading = false
    this.isOpen = false
  }
}
