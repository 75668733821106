import { calendarEventStore } from '@/services/CalendarEvent/store'

export function mounted() {
  this.unsubCalendarEventStore = calendarEventStore.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'updateMain') {
      this.refresh()
    }
  })
}
