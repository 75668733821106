/*
  import { calendarEventStore } from '@/services/CalendarEvent/store'

  this.unsubCalendarEventStore = calendarEventStore.subscribe((mutation, state) => {
    const mType = mutation.type;


  })

  this.unsubCalendarEventStore();
*/

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const calendarEventStore = new Vuex.Store({
  state: {},
  mutations: {
    /*
      import { calendarEventStore } from '@/services/CalendarEvent/store'
			await calendarEventStore.commit ('updateMain');
    */
    async updateMain() {}
  },
  /* eslint-disable no-empty-pattern */
  actions: {},
  /* eslint-enable no-empty-pattern */
  getters: {}
})

//
