<template>
  <b-select v-model="occasionSelection">
    <option v-for="option in occasions" :value="option" :key="option">
      {{ option }}
    </option>
  </b-select>
</template>

<script>
export default {
  props: {
    onChange: {
      default() {},
      type: Function
    },
    includeEvery: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      occasions: this.includeEvery
        ? Object.freeze(['Any', '1st', '2nd', '3rd', '4th', '5th'])
        : Object.freeze(['1st', '2nd', '3rd', '4th', '5th']),
      occasionSelection: this.includeEvery ? 'Any' : '1st'
    }
  },
  methods: {
    getValue() {
      return this.occasionSelection
    }
  },
  watch: {
    occasionSelection() {
      this.onChange({
        occasion: this.occasionSelection
      })
    }
  }
}
</script>
