<template>
  <main>
    <!------------------------------->
    <!--(        the month        )-->
    <!------------------------------->
    <section
      class="card"
      :style="{
        padding: '12px',
        marginBottom: '12px',
        overflow: 'visible',
        border: checked.month ? `3px solid ${color[0]}` : '3px solid transparent'
      }"
    >
      <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }">
        <b-field :style="{ margin: 0 }">
          <b-checkbox v-model="checked.month" month-check-box>the month</b-checkbox>
          <b-select v-model="month.optionSelected" month-design-select>
            <option v-for="option in month.options" :key="option[1]" :value="option[0]">
              {{ option[1] }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div
        :style="{
          position: 'relative',
          padding: '20px 0 40px 40px'
        }"
      >
        <div
          v-if="month.optionSelected === 1"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }"
          month-selector-container
        >
          <b-taginput
            autocomplete
            months-selected
            :style="{
              float: 'right'
            }"
            v-model="monthsSelected"
            :data="filteredMonths"
            :open-on-focus="true"
            :allow-new="false"
            placeholder="month(s) chooser"
            @typing="getFilteredMonths"
            type="is-primary"
          >
          </b-taginput>
        </div>
        <div
          v-if="month.optionSelected === 2"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }"
          month-range-selector-container
        >
          <div :style="{ display: 'flex' }" from-location>
            <MonthAndDay ref="monthAndDay1" :monthAndDay="monthInclusiveBetween[0]" />
          </div>

          <span :style="{ width: '12px' }"></span>

          <div :style="{ display: 'flex' }" to-location>
            <MonthAndDay ref="monthAndDay2" :monthAndDay="monthInclusiveBetween[1]" />
          </div>
        </div>
        <div
          v-if="!checked.month"
          :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.8,
            background: '#fff'
          }"
        ></div>
      </div>
    </section>

    <!------------------------------->
    <!--{      day of month       }-->
    <!------------------------------->
    <section
      class="card"
      :style="{
        padding: '12px',
        marginBottom: '12px',
        overflow: 'visible',
        border: checked.dayOfMonth ? `3px solid ${color[0]}` : '3px solid transparent'
      }"
    >
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }"
      >
        <b-field :style="{ margin: 0 }">
          <b-checkbox v-model="checked.dayOfMonth" day-of-month-check-box
            >the day of the month</b-checkbox
          >
          <b-select v-model="dayOfMonth.optionSelected">
            <option v-for="option in dayOfMonth.options" :key="option[1]" :value="option[0]">
              {{ option[1] }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div
        :style="{
          position: 'relative',
          padding: '20px 0 20px 40px'
        }"
      >
        <div
          v-if="dayOfMonth.optionSelected === 1"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }"
        >
          <b-taginput
            autocomplete
            :style="{
              float: 'right'
            }"
            v-model="daysOfMonthSelected"
            :data="filteredDaysOfMonth"
            :open-on-focus="true"
            :allow-new="false"
            placeholder="Day(s) of month chooser"
            @typing="getFilteredDaysOfTheMonth"
            type="is-primary"
          >
          </b-taginput>
        </div>
        <div
          v-if="dayOfMonth.optionSelected === 2"
          :style="{
            display: 'flex',
            alignItems: 'center'
          }"
        >
          <DayOfMonth ref="dayOfMonth1" :includeLast="true" :value="dayOfMonth1Value" />
          <span :style="{ padding: '0 6px' }">and the</span>
          <DayOfMonth ref="dayOfMonth2" :includeLast="true" :value="dayOfMonth2Value" />
        </div>

        <div
          v-if="!checked.dayOfMonth"
          :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.8,
            background: '#fff'
          }"
        ></div>
      </div>
    </section>

    <!------------------------------->
    <!--|/     day of week       \|-->
    <!------------------------------->
    <section
      class="card"
      :style="{
        padding: '12px',
        marginBottom: '12px',
        overflow: 'visible',
        border: checked.dayOfWeek ? `3px solid ${color[0]}` : '3px solid transparent'
      }"
    >
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }"
      >
        <b-field :style="{ margin: 0 }">
          <b-checkbox v-model="checked.dayOfWeek" day-of-week-check-box
            >The day of the week is the</b-checkbox
          >
        </b-field>
      </div>

      <div
        :style="{
          paddingLeft: '40px'
        }"
      >
        <div>
          <div :style="{ display: 'flex', alignItems: 'center' }">
            <OccasionOfDayOfWeek
              ref="occasionOfDayOfWeek"
              :onChange="onOccasionOfDayOfWeekChange"
            />
            <DayOfWeek ref="dayOfWeek" :onChange="onDayOfWeekChange" />
            <div :style="{ padding: '0 12px' }">of the month</div>
            <b-button type="is-primary" :style="{ marginLeft: '12px' }" @click="addDayOfWeek"
              >add</b-button
            >
          </div>
        </div>

        <div
          :style="{
            display: 'flex'
          }"
        >
          <div
            v-for="(dayOfWeek, index) in daysOfWeekSelected"
            :key="index"
            :style="{
              verticalAlign: 'center',
              margin: '4px'
            }"
          >
            <b-taglist attached>
              <b-tag type="is-dark" v-if="dayOfWeek.occasion !== 'Any'">{{
                dayOfWeek.occasion
              }}</b-tag>

              <b-tag type="is-primary" closable @close="eraseDayOfWeek({ dayOfWeek })">{{
                dayOfWeek.dayOfWeek
              }}</b-tag>
            </b-taglist>
          </div>
        </div>
      </div>
    </section>

    <!------------------------------->
    <!--|_       time            _|-->
    <!------------------------------->
    <section
      class="card"
      :style="{
        padding: '12px',
        marginBottom: '12px',
        overflow: 'visible',
        border: checked.time ? `3px solid ${color[0]}` : '3px solid transparent'
      }"
    >
      <b-field :style="{ margin: 0 }">
        <b-checkbox v-model="checked.time" time-check-box>The event starts at</b-checkbox>
      </b-field>

      <b-field
        :style="{
          margin: 0,
          opacity: checked.time ? 1 : 0.3,
          transition: 'opacity .3s'
        }"
      >
        <div>
          <TimePicker ref="time" v-model="time" />

          <div :style="{ padding: '10px 0' }">and lasts for</div>

          <div :style="{ display: 'flex' }">
            <b-field label="Minutes">
              <b-input :style="{ width: '100px' }" v-model="minutes"></b-input>
            </b-field>
          </div>
        </div>
      </b-field>
    </section>
  </main>
</template>

<script>
/*
	<Variables ref="variables" />
*/

import TimePicker from '@/components/inputs/TimePickerFifteen'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { props } from './keys/props'

import DayOfMonth from '@/components/inputs/DayOfMonth'
import OccasionOfDayOfWeek from '@/components/inputs/OccasionOfDayOfWeek'
import DayOfWeek from '@/components/inputs/DayOfWeek'
import MonthAndDay from '@/components/inputs/MonthAndDay'

export default {
  components: {
    DayOfMonth,
    MonthAndDay,
    OccasionOfDayOfWeek,
    DayOfWeek,
    TimePicker
  },
  data,
  methods,
  props
}
</script>
