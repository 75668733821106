import { notifyMessage, notifyProblem } from '@/services/notify'

import { allDatesInMonth } from '@/utilities/Date/allDatesInMonth'
import moment from 'moment'
import { addRecurringEvent } from '@/services/CalendarEvent/CommunityCalendarRecurringEvent/Post'

import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import { calendarEventStore } from '@/services/CalendarEvent/store'

import { eraseRecurringEvent } from '@/services/CalendarEvent/CommunityCalendarRecurringEvent/Delete'
import { updateRecurringEvent } from '@/services/CalendarEvent/CommunityCalendarRecurringEvent/Put'

import { presets } from './presets'

export const methods = {
  setUpdate() {
    this.mode = 'update'
  },

  openUpdate({ event, recurringEvents, recurringEvent }) {
    this.reset()

    this.mode = 'view'
    this.original = _cloneDeep(event.event)
    this.recurringEvent = _cloneDeep(recurringEvent)

    const evt = event.event

    this.title = _get(evt, 'title', '')
    this.json.title = this.title

    this.description = _get(evt, 'description', '')
    this.json.description = this.description

    // filters
    const startDate = _get(evt, 'recurrenceDefinition.startDate', '')
    const endDate = _get(evt, 'recurrenceDefinition.endDate', '')
    const formattedDateStart = moment(startDate).format('LL hh:mm A')
    const recurrenceDefinition = _get(recurringEvent, 'recurrenceDefinition', {})
    const formattedDateEnd = endDate ? moment(endDate).format('LL hh:mm A') : `Recurring`
    this.dates = formattedDateStart + ' to ' + formattedDateEnd

    this.url = _get(evt, 'url', '')
    this.json.url = this.url

    this.json.communityCalendarRecurringEventID = recurringEvent.communityCalendarRecurringEventID

    // filters
    this.startDate = _get(recurrenceDefinition, 'startDate', '')
    this.endDate = _get(recurrenceDefinition, 'endDate', '')

    this.json.recurrenceDefinition = {}
    for (let key in recurrenceDefinition) {
      this.json.recurrenceDefinition[key] = recurringEvents[key]
    }

    this.isOpen = true
    this.loading = false
  },
  async update() {
    this.loading = true

    const json = _cloneDeep(this.json)

    json.RecurrenceJSON = ''
    json.hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { successful, message } = await updateRecurringEvent({
      json
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    await calendarEventStore.commit('updateMain')

    this.isOpen = false
    this.loading = false
  },

  async openConfirmErase() {
    this.mode = 'delete'
  },

  async eraseEvent() {
    this.loading = true

    const { communityCalendarRecurringEventID, title } = this.json

    const { successful, message } = await eraseRecurringEvent({
      communityCalendarRecurringEventID
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    await calendarEventStore.commit('updateMain')

    notifyMessage(`Event "${title}" has been deleted.`)

    this.isOpen = false
    this.loading = false
  },

  onOnChange() {},

  open() {
    this.reset()

    this.isOpen = true
    this.loading = false
  },

  reset() {
    const _presets = _cloneDeep(presets)
    for (let key in _presets) {
      this[key] = _presets[key]
    }
  },

  async add() {
    this.loading = true

    this.json.recurrenceJSON = ''

    this.json.hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { successful, message } = await addRecurringEvent({
      json: this.json
    })
    if (!successful) {
      console.error(message)
      return
    }

    await calendarEventStore.commit('updateMain')

    this.isOpen = false
    this.loading = false
  },

  getDaysOfMonth() {
    const m = moment()

    const month = m.month() + 1
    const year = m.year()

    const { dates } = allDatesInMonth({
      year,
      month,
      suffix: true
    })

    return dates
  },

  async checkStep({ forward }) {
    const step = this.step

    console.log({ step })

    if (step === 0) {
      this.loading = true

      const title = this.title
      const description = this.description
      const url = this.url

      if (typeof title !== 'string') {
        notifyProblem(`There was a problem while parsing the "title".`)

        return { proceed: false }
      }
      if (title.length === 0) {
        notifyProblem(`A "title" is required.`)

        return { proceed: false }
      }

      if (typeof description !== 'string') {
        notifyProblem(`There was a problem while parsing the "description".`)

        return { proceed: false }
      }
      if (description.length === 0) {
        notifyProblem(`A "description" is required.`)

        return { proceed: false }
      }

      this.json.title = title
      this.json.description = description
      this.json.url = url

      if (this.mode === 'update') {
        this.variableRangesPresets = this.recurrenceDefinition
        await this.$refs.variableRanges.setValues({
          recurringEvent: this.recurringEvent
        })
      }

      return { proceed: true }
    } else if (step === 1) {
      const startDate = this.startDate
      const endDate = this.endDate

      if (this.mode === 'update') {
        if (typeof startDate === 'string' && startDate.length >= 1) {
          this.$refs.datePickerAfter.setValue(startDate)
        }
        if (typeof endDate === 'string' && endDate.length >= 1) {
          this.$refs.datePickerBefore.setValue(endDate)
          this.beforeTimeSelected = true
        }
      }

      this.loading = true

      const variableRanges = this.$refs.variableRanges.getValues()

      /*
				recurrenceDefinition: {
	        month: [],
	        monthInclusiveBetween: [],
	        dayOfMonth: [],
	        dayOfMonthInclusiveBetween: [],
	        dayOfWeek: [ 'Tuesday' ],
	        startTime: null,
	        durationMinutes: null,
	        startDate: '2022-01-01',
	        endDate: null
	      }
			*/

      this.json.recurrenceDefinition = {}
      for (let key in variableRanges) {
        this.json.recurrenceDefinition[key] = variableRanges[key]
      }

      return { proceed: true }
    } else if (step === 2) {
      const datePickerAfter = this.$refs.datePickerAfter.getValue()

      const afterUTCFormat = _get(datePickerAfter, 'utcFormat', null)
      if (typeof afterUTCFormat !== 'string' || afterUTCFormat.length === 0) {
        notifyProblem('Choosing an after date is required.')
        return { proceed: false }
      }

      let after = ''
      try {
        after = datePickerAfter.utcFormat.split('T')[0]

        if (typeof after === 'string' && after.length >= 10) {
          this.json.recurrenceDefinition.startDate = after
        }
      } catch (x) {
        console.error(x)
      }

      const beforeTimeSelected = this.beforeTimeSelected

      let before = ''
      if (beforeTimeSelected) {
        const datePickerBefore = this.$refs.datePickerBefore.getValue()

        try {
          before = datePickerBefore.utcFormat.split('T')[0]

          if (typeof before === 'string' && before.length >= 10) {
            this.json.recurrenceDefinition.endDate = before
          }
        } catch (x) {
          console.error(x)
        }
      }

      return { proceed: true }
    } else if (step === 3) {
      if (forward === false) {
        return { proceed: true }
      }
    }

    return { proceed: false }
  },
  async nextStep() {
    const { proceed } = await this.checkStep({
      forward: true
    })

    this.loading = false

    if (proceed) {
      this.step++
    }

    if (this.step === 3) {
      console.trace('nextStep', {
        json: this.json
      })
      this.confirmationData = {
        json: this.json
      }

      this.addPrevented = false
      this.updatePrevented = false
    } else {
      this.addPrevented = true
      this.updatePrevented = true
    }
  },
  async prevStep() {
    const { proceed } = await this.checkStep({
      forward: false
    })

    this.loading = false

    this.addPrevented = true
    this.updatePrevented = true

    if (proceed) {
      this.step--
    }
  }
}
