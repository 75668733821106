/*
	import { orList } from '@/utilities/String/orList'
*/

export function orList(arr) {
  const len = arr.length

  let result = ''

  if (len === 1) {
    result += ' ' + arr[0]
  } else if (arr.length === 2) {
    result += ' either'
    result += ' ' + arr[0] + ' or ' + arr[1]
  } else {
    result += ' either'
    for (let a = 0; a <= len - 2; a++) {
      result += ' ' + arr[a] + ','
    }
    result += ' or ' + arr[len - 1]
  }

  return result
}
