export function data() {
  return {
    loading: false,
    isOpen: false,
    title: '',
    description: '',
    url: '',
    dates: '',
    page: 0,

    eventStart: '',
    eventEnd: ''
  }
}
