/*
	import { addEvent } from '@/services/CalendarEvent/CommunityCalendarEvent/Post';

  const { result, successful, message } = await addEvent ({
    json: {
      hoaID,
      title,
      description,
      startTime,
      endTime
    }
  });
  if (!successful) {
    notifyProblem (message);
    return;
  }
*/

/*
  import { addEventPath } from '@/services/CalendarEvent/CommunityCalendarEvent/Post';
*/

import kms from '@/services/kms'

export const addEventPath = `/CalendarEvent/CommunityCalendarEvent`

export async function addEvent({ json }) {
  let message = 'The community calendar events list could not be retrieved.'

  try {
    const result = await kms.post(addEventPath, json)

    return {
      successful: true,
      message,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: {}
  }
}
