<template>
  <Modal event-adder :toggle.sync="isOpen" :loading="loading">
    <template v-slot:header>
      <div :style="{ margin: '12px 0' }">
        <h4 :style="{ margin: 0 }">{{ title }}</h4>
        <div>{{ dates }}</div>
      </div>
    </template>

    <div :style="{ minHeight: '300px' }">
      <div v-if="page === 0">
        <div>{{ `Description: ${description}` }}</div>
        <div v-if="url">
          {{ `URL:` }} <a :href="`http://${url}`" target="_blank">{{ url }}</a>
        </div>
      </div>

      <div v-if="page === 1">
        <b-field label="Title">
          <b-input v-model="title"></b-input>
        </b-field>

        <b-field label="Description">
          <b-input v-model="description" type="textarea"></b-input>
        </b-field>

        <b-field label="URL">
          <b-input v-model="url"></b-input>
        </b-field>

        <b-field label="From" />
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <DateTimePicker :point="eventStart" ref="dateTimePicker1" />
        </div>

        <br />

        <b-field label="To" />
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <DateTimePicker :point="eventEnd" ref="dateTimePicker2" />
        </div>
      </div>

      <div v-if="page === 2">
        <p>To delete the event, press the delete button.</p>
      </div>
    </div>

    <template v-slot:footer>
      <div :style="{ display: 'flex', justifyContent: 'space-between' }" v-if="page === 0">
        <b-button type="is-danger" @click="showDeletionConfirmation">Delete</b-button>
        <b-button type="is-primary" @click="showUpdatePanel">Update</b-button>
      </div>

      <div :style="{ display: 'flex', justifyContent: 'space-between' }" v-if="page === 1">
        <b-button type="is-primary" @click="back">Back</b-button>
        <b-button type="is-primary" @click="updateEvent">Update Event</b-button>
      </div>

      <div :style="{ display: 'flex', justifyContent: 'space-between' }" v-if="page === 2">
        <b-button @click="back">Back</b-button>
        <b-button type="is-danger" @click="deleteEvent">Delete</b-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import DateTimePicker from '@/components/inputs/DateTimePicker'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    DateTimePicker
  },
  data,
  methods
}
</script>
