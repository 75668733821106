export function data() {
  return {
    loading: true,
    isOpen: false,
    fields: {
      title: '',
      description: '',
      url: ''
    }
  }
}
