import { presets } from './presets'
import _cloneDeep from 'lodash/cloneDeep'

export function data() {
  const _presets = _cloneDeep(presets)

  const data = {}
  for (let key in _presets) {
    data[key] = _presets[key]
  }

  return data
}
