<template>
  <PageContentLayoutOnly>
    <div :style="{ marginBottom: '20px' }">
      <b-button rounded type="is-primary" @click="openEventAdder" add-event>Add Event</b-button>

      <b-button
        rounded
        type="is-primary"
        @click="openRecurringEventAdder"
        :style="{ marginLeft: '12px' }"
        add-recurring-event
        >Add Recurring Event</b-button
      >

      <!--       <b-button 
        rounded 
        type="is-primary" 
        @click="openEventsList"
        events-list-button
        :style="{ float: 'right' }"
      >Events List</b-button> -->
    </div>

    <PleasantCalendar
      ref="calendar"
      :pageChange="calendarUpdate"
      :ready="ready"
      :dayTapped="dayTapped"
      :eventTapped="eventTapped"
      :events="events"
      :externalProblem="calendarProblem"
    />

    <EventAdder ref="eventAdder" />
    <RecurringEventAdder ref="recurringEventAdder" />

    <EventDetails ref="eventDetails" />
  </PageContentLayoutOnly>
</template>

<script>
import EventAdder from '../EventAdder'
import EventDetails from '../EventDetails'
import RecurringEventAdder from '../RecurringEventAdder'

import PleasantCalendar from '@/components/Calendars/Pleasant'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'

export default {
  components: {
    EventAdder,
    RecurringEventAdder,
    //
    EventDetails,
    //
    PageContentLayoutOnly,
    PleasantCalendar
  },
  data,
  methods,
  mounted
}
</script>
