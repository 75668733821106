import { notifyProblem } from '@/services/notify'

import { listEvents } from '@/services/CalendarEvent/CommunityCalendarEvent/List'
import { listRecurringEvents } from '@/services/CalendarEvent/CommunityCalendarRecurringEvent/List'
import { getCalendarEvents } from '@/services/CalendarEvent/Calendaring/GetCalendarEvents'
import { lastDayOfMonth } from '@/utilities/Date/lastDayOfMonth'

import { monthNumerals } from '@/utilities/Date/constants'

import moment from 'moment'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import { getArray } from '@/utilities/get/Array'
import { getNumber } from '@/utilities/get/number'

export const methods = {
  ready({ month, year }) {
    this.isReady = true

    this.calendarUpdate({ month, year })
  },
  async calendarUpdate({ month, year }) {
    this.month = month
    this.year = year

    await this.refresh()
  },

  openEventsList() {
    // console.log ('openEventsList')
  },

  dayTapped() {},

  eventTapped({ event }) {
    const communityCalendarRecurringEventID = _get(
      event,
      ['communityCalendarRecurringEventID'],
      null
    )

    let isRecurring = false
    if (typeof communityCalendarRecurringEventID === 'number') {
      isRecurring = true
    }

    let recurringEvent = null
    const recurringEvents = this.recurringEvents
    for (let a = 0; a < recurringEvents.length; a++) {
      if (
        recurringEvents[a].communityCalendarRecurringEventID === communityCalendarRecurringEventID
      ) {
        recurringEvent = _cloneDeep(recurringEvents[a])
      }
    }

    if (isRecurring) {
      this.$refs.recurringEventAdder.openUpdate({
        event,
        recurringEvents: this.recurringEvents,
        recurringEvent
      })
      return
    }

    this.$refs.eventDetails.open({
      event
    })
  },

  openRecurringEventAdder() {
    this.$refs.recurringEventAdder.open()
  },
  openEventAdder() {
    this.$refs.eventAdder.open()
  },

  async parseEvents({ list }) {
    if (!Array.isArray(list)) {
      this.events = []
      return
    }

    const events = list.map(event => {
      const from = moment(event.startTime)
        .utcOffset(0, true)
        .toISOString()
      const to = moment(event.endTime)
        .utcOffset(0, true)
        .toISOString()

      return {
        startDate: from,
        endDate: to,
        //
        communityCalendarEventID: event.communityCalendarEventID,
        //
        name: event.title,
        description: event.description,
        url: event.url,
        //
        event
      }
    })

    return {
      events
    }
  },
  async parseRecurringEvents({ eventDates, recurringEventsKV }) {
    const eventsList = []
    for (let a = 0; a < eventDates.length; a++) {
      const { events } = eventDates[a]

      for (let b = 0; b < events.length; b++) {
        const event = events[b]
        eventsList.push(event)
      }
    }

    for (let a = 0; a < eventsList.length; a++) {
      const event = eventsList[a]
      const communityCalendarRecurringEventID = getNumber(
        event,
        'communityCalendarRecurringEventID',
        -1
      )

      if (
        Object.prototype.hasOwnProperty.call(recurringEventsKV, communityCalendarRecurringEventID)
      ) {
        event.details = recurringEventsKV[communityCalendarRecurringEventID]
      } else {
        event.details = {}
      }
    }

    /*
			communityCalendarEventID: null
			communityCalendarRecurringEventID: 53

			displayEndTime: "2022-03-14T23:59:59"
			displayStartTime: "2022-03-14T00:00:00"
			
			eventEndTime: "2022-03-14T23:59:59"
			eventStartTime: "2022-03-14T00:00:00"
			
			eventType: "RecurringEvent"
		*/
    const events = eventsList
      .filter(event => {
        if (_get(event, 'eventType', '') !== 'RecurringEvent') {
          return false
        }

        return true
      })
      .map(event => {
        const from = moment(event.displayStartTime)
          .utcOffset(0, true)
          .toISOString()
        const to = moment(event.displayEndTime)
          .utcOffset(0, true)
          .toISOString()
        const communityCalendarRecurringEventID = getNumber(
          event,
          'communityCalendarRecurringEventID',
          -1
        )

        const name = _get(event, ['details', 'title'], '')
        const description = _get(event, ['details', 'description'], '')

        return {
          name,
          description,
          //
          communityCalendarRecurringEventID,
          //
          startDate: from,
          endDate: to,
          //
          event: _get(event, ['details'], {})
        }
      })

    return { events }
  },

  async getCalendarEvents({ hoaID, interval, startDate, endDate }) {
    const { result, successful, message } = await getCalendarEvents({
      params: {
        hoaID,
        interval,

        startDate,
        endDate
      }
    })
    if (!successful) {
      notifyProblem(message)

      this.calendarProblem = `The events for the month and year selected could not be retrieved.`

      return {
        result: {}
      }
    }

    this.calendarProblem = ``

    return {
      result
    }
  },
  async getRecurringEvents() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list, successful, message } = await listRecurringEvents({
      params: {
        hoaID
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    const kv = {}

    if (Array.isArray(list) && list.length >= 1) {
      for (let a = 0; a < list.length; a++) {
        const details = list[a]
        const communityCalendarRecurringEventID = getNumber(
          details,
          'communityCalendarRecurringEventID',
          -1
        )

        kv[communityCalendarRecurringEventID] = details
      }
    }

    return {
      recurringEvents: list,
      recurringEventsKV: kv
    }
  },

  //
  async refresh() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list, successful, message } = await listEvents({
      params: {
        hoaID
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    const month = monthNumerals[this.month]
    let year = Number.prototype.toString.call(this.year)
    const { dayOfMonth } = lastDayOfMonth({ year, month })

    let dayOfMonthStr = Number.prototype.toString.call(dayOfMonth)
    if (dayOfMonthStr.length === 1) {
      dayOfMonthStr = '0' + dayOfMonthStr
    }

    if (typeof this.year === 'number' && this.year >= 1 && [1, 2, 3].includes(year.length)) {
      if (year.length === 1) {
        year = '000' + year
      }
      if (year.length === 2) {
        year = '00' + year
      }
      if (year.length === 3) {
        year = '0' + year
      }
    }

    /*
			This is necessary so as to get the details of the recurring event
		*/
    const { recurringEvents, recurringEventsKV } = await this.getRecurringEvents()
    const { result } = await this.getCalendarEvents({
      hoaID,
      interval: 'day',

      startDate: `${year}-${month}-01`,
      endDate: `${year}-${month}-${dayOfMonthStr}`
    })

    const eventDates = getArray(result, 'eventDates', [])

    const { events: rEvents } = await this.parseRecurringEvents({
      eventDates,
      recurringEventsKV
    })
    const { events } = await this.parseEvents({
      list
    })

    console.log({
      rEvents,
      events
    })

    this.recurringEvents = recurringEvents
    this.events = [...rEvents, ...events]
  }
}
