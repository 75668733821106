/*
	import { recurringEventConditions } from '@/utilities/events/recurringEventConditions'

  const { conditions } = recurringEventConditions ({
    recurrenceDefinition
  })
*/

import { addSuffix } from '@/utilities/Date/addSuffix'
import { orList } from '@/utilities/String/orList'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'

export function recurringEventConditions({ recurrenceDefinition }) {
  const month = _get(recurrenceDefinition, 'month', [])
  const monthInclusiveBetween = _get(recurrenceDefinition, 'monthInclusiveBetween', [])
  const dayOfMonth = _get(recurrenceDefinition, 'dayOfMonth', [])
  const dayOfMonthInclusiveBetween = _get(recurrenceDefinition, 'dayOfMonthInclusiveBetween', [])
  const dayOfWeek = _get(recurrenceDefinition, 'dayOfWeek', [])

  const startTime = _get(recurrenceDefinition, 'startTime', null)
  const durationMinutes = _get(recurrenceDefinition, 'durationMinutes', null)

  const startDate = _get(recurrenceDefinition, 'startDate', null)
  const endDate = _get(recurrenceDefinition, 'endDate', null)

  const conditions = []

  /*****************************************************
			Box 1
		*/
  if (Array.isArray(month) && month.length >= 1) {
    conditions.push(`The month is` + orList(month))
  }
  if (Array.isArray(monthInclusiveBetween) && monthInclusiveBetween.length == 2) {
    /*
				The date is between January 1st and February 8th
			*/
    try {
      const from = monthInclusiveBetween[0].split('-')
      const to = monthInclusiveBetween[1].split('-')

      const fromDayOfMonth = parseInt(from[1])
      const toDayOfMonth = parseInt(to[1])

      const str =
        'The date is between ' +
        from[0] +
        ' ' +
        addSuffix(fromDayOfMonth) +
        ' to ' +
        to[0] +
        ' ' +
        addSuffix(toDayOfMonth)

      conditions.push(str)
    } catch (x) {
      console.error(x)
    }
  }
  //*********************************************************

  /*****************************************************
			Box 2
		*/
  if (Array.isArray(dayOfMonth) && dayOfMonth.length >= 1) {
    conditions.push(`The day of the month is ` + orList(dayOfMonth))
  }
  if (Array.isArray(dayOfMonthInclusiveBetween) && dayOfMonthInclusiveBetween.length >= 1) {
    let from = dayOfMonthInclusiveBetween[0]
    let to = dayOfMonthInclusiveBetween[1]

    if (from[0] === '0') {
      from = from[1]
    }
    if (to[0] === '0') {
      to = to[1]
    }

    conditions.push(`The day of the month is between the ${addSuffix(from)} and ${addSuffix(to)}`)
  }
  //*********************************************************

  /*****************************************************
			Box 3
		*/
  if (Array.isArray(dayOfWeek) && dayOfWeek.length >= 1) {
    const _dayOfWeek = dayOfWeek.map(entry => {
      if (entry.indexOf('-') >= 1) {
        const split = entry.split('-')

        return addSuffix(split[1]) + ' ' + split[0]
      }

      return entry
    })

    conditions.push(`The day of the week is ` + orList(_dayOfWeek))
  }
  //*********************************************************

  /*****************************************************
			Box 4
		*/
  if (typeof startTime === 'string' && startTime.length === 8) {
    conditions.push(
      `The time is between ` + startTime + ` and before ` + durationMinutes + ` minutes after that`
    )
  }
  //*********************************************************

  /**********************************************************
			Filters
		*/
  //*********************************************************
  conditions.push(`The date is after ` + startDate)

  if (typeof endDate === 'string' && endDate.length >= 1) {
    conditions.push(`The date is before ` + endDate)
  }

  return {
    conditions
  }
}

export function recurrenceTimeSpan({ event }) {
  const dateFormat = 'LL hh:mm a'

  const conditions = []

  const eventStartDate = parseDate(_get(event, 'startDate', null), dateFormat)
  const eventEndDate = parseDate(_get(event, 'endDate', null), dateFormat)
  conditions.push(`The event is scheduled for ${eventStartDate} to ${eventEndDate}`)

  return {
    conditions
  }
}
