export const props = {
  onChange: {
    default() {},
    type: Function
  },
  includeTime: {
    default: true,
    type: Boolean
  },
  includeYear: {
    default: true,
    type: Boolean
  },
  includeTimeRange: {
    default: false,
    type: Boolean
  },
  presets: {}
}
